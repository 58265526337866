import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby-plugin-spinner"
import SEO from "./../components/seo"

import Layout from "../components/layout"
import slugify from "slugify"
import { select_couleur } from "../services/selectNiveaux"
import ImgBaguette from "../components/imgBaguette"
import { niveaux } from "../services/prixBaguettes"

class CadresPage extends React.Component {
  render() {
    let uniqueColors = []
    this.props.queries.allMysqlNewProduit.edges.forEach(edge => {
      uniqueColors[edge.node.cog_id] = edge.node
    })
    const couleurs = select_couleur(
      this.props.queries.allMysqlNewProduit.edges.map(e => e.node),
      this.props.queries.allMysqlNewProduit.edges.map(e => e.node)
    )

    return (
      <>
        <SEO title={`Notre collection de cadres`} description="Liste des catégories et des couleurs de cadres" />
        <Layout banner={false} location={this.props.location}>
          <h1 style={{ marginTop: "6rem", textAlign: "center" }}>Notre collection de cadres</h1>

          <div className="columns is-desktop is-centered" style={{ marginBottom: "4rem" }}>
            <div className="column is-8">
              <h2 style={{ marginBottom: ".8rem", color: "black", textAlign: "center", fontSize: "1.8em" }}>Je choisis mon cadre : </h2>
              <div className="columns is-desktop">
                <div className="column">
                  <h3>Par couleur</h3>
                  <div className="couleursColumns">
                    {couleurs.sort().map(({ node }, indexColor) => (
                      <div key={indexColor} className="couleurList">
                        <Link to={`/couleur/` + slugify(node.cog_libelle)}>
                          {node.cog_image_ba_id !== null ? (
                            <>
                              <div title={node.cog_libelle} className={`couleur`} tabIndex={0} role="button">
                                <ImgBaguette id={node.cog_image_ba_id} type="coin" style={{ height: "100%", marginTop: "0px" }} alt={"Couleur de baguette"} />
                              </div>
                              <p className="has-text-left">{node.cog_libelle}</p>
                            </>
                          ) : (
                            <>
                              <div title={node.cog_libelle} style={{ backgroundColor: node.cog_hexa }} className={`couleur`} tabIndex={0} role="button"></div>
                              <p>{node.cog_libelle}</p>
                            </>
                          )}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column is-narrow">
                  <h3>Par catégorie</h3>
                  <ul className="menuCategory" style={{ background: "#F3F3F3" }}>
                    {niveaux.map((edge, key) => (
                      <>
                        <li key={`1-{key}`} className="menuCategoryTitle">
                          {edge.title}
                        </li>
                        {edge.categs.map((categ, key2) => {
                          categ = this.props.queries.allMysqlCategories.edges.find(e => e.node.cat_id == categ)
                          return (
                            <li key={`${key}-${key2}`}>
                              <Link className={`navbar-item`} to={`/categorie/` + slugify(categ.node.cat_libelle.replace('(', '').replace(')', '').replace('...', ''))}>
                                {categ.node.cat_libelle}
                              </Link>
                            </li>
                          )
                        })}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}
CadresPage.propTypes = {
  identifiants: PropTypes.object,
  queries: PropTypes.object,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

const ConnectFunction = connect(mapStateToProps)(CadresPage)

const StaticCadrePage = props => (
  <StaticQuery
    query={graphql`
      query queries {
        allMysqlCategories {
          edges {
            node {
              id
              cat_libelle
              cat_order
              cat_id
            }
          }
        }
        allMysqlNewProduit(filter: { isDoublo: { eq: 0 } }) {
          edges {
            node {
              co_id
              co_libelle
              co_fk_cog
              cog_id
              cog_libelle
              cog_hexa
              cog_order
              cog_ba_ref
              ba_ref_interne
              ba_id
              photo_montage
            }
          }
          distinct(field: cog_id)
        }
      }
    `}
    render={queries => <ConnectFunction queries={queries} {...props} />}
  />
)

export default StaticCadrePage
